<template>
    <v-sheet tabindex="0" :color="dragging ? 'grey' : 'grey lighten-3'" class="file-input"
        :style="{ height: `${height}px` }" @dragenter="dragging = true" @dragleave="dragging = false" @drag="change">
        <div class="drop-label">
            <v-icon x-large>fas fa-file</v-icon>
            <span class="mt-3">Sleep hier een bestand naartoe of klik.</span>
        </div>
        <input type="file" :multiple="multiple" :accept="accept" @change="change" />
    </v-sheet>
</template>

<script>
export default {
    props: {
        height: String,
        accept: String,
        multiple: Boolean,
        input: Function,
    },
    data: () => ({
        dragging: false
    }),
    methods: {
        change(e) {
            this.dragging = false
            if (!e.target.files || e.target.files.length === 0) return

            let files = []
            for (const file of e.target.files) {
                files.push(file)
            }
            this.input(files)
        }
    }
}
</script>

<style lang="scss" scoped>
.drop-label {
    display: flex;
    flex-direction: column;
}

.file-input {
    position: relative;
    display: grid;
    place-items: center;
    width: 100%;
    min-height: 200px;
    border-radius: 5px;

    input {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}
</style>