<template>
	<v-app>
		<topmenubar />
		<v-main class="main-top-card-border">
			<v-container class="container-small">
				<v-row>
					<v-col cols="2"></v-col>
					<v-col cols="6">
						<h1>Account Informatie</h1>
						<p></p>
						<v-form ref="form" lazy-validation v-model="valid" @submit.prevent="submit" class="py-5">
							<v-row>
								<v-col cols="12">
									<v-text-field v-model="account.firstname" color="purple darken-2" label="Naam"></v-text-field>
								</v-col>

								<v-col cols="12">
									<v-text-field v-model="account.lastname" color="purple darken-2" label="Achternaam"></v-text-field>
								</v-col>

								<v-col cols="12">
									<v-combobox label="Werkgever" :items="employers" item-text="title" item-value="id" v-model="account.employer" color="purple darken-2" persistent-hint></v-combobox>
								</v-col>

								<v-col cols="12">
									<v-text-field v-model="account.functiontitle" color="purple darken-2" label="Functie"></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-select label="Selecteer je actieve rol" :items="roles" item-value="val" item-text="title" v-model="account.role"></v-select>
								</v-col>
								<v-col cols="12">
									<v-select label="Selecteer je actieve programma" :items="programs" item-value="program" item-text="title" v-model="account.program">
										<template v-slot:append>
											<!-- Voeg een v-if statement toe om de pencil knop te tonen als item.owner gelijk is aan true -->
											<!-- <v-btn v-if="item.owner == user.id" class="mx-auto d-block" fab small elevation="0" color="white" @click="editprogramdialog = true">
												<v-icon dark> mdi-pencil </v-icon>
											</v-btn> -->
											<v-btn class="mx-auto d-block" fab small elevation="0" color="white" @click="createprogramdialog = true">
												<v-icon dark> mdi-plus </v-icon>
											</v-btn>
										</template>
									</v-select>
								</v-col>

								<v-col cols="12">
									<v-text-field v-model="account.passwd" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="rules.pass" :type="show ? 'text' : 'password'" label="Wachtwoord" color="purple darken-2" @click:append="show = !show" validate-on-blur v-on:blur="validate"></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field v-model="account.rpasswd" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="rules.pass" :type="show ? 'text' : 'password'" label="Wachtwoord herhalen" color="purple darken-2" @click:append="show = !show" validate-on-blur v-on:blur="validate"></v-text-field>
									<p class="text-error">{{ passmessage }}</p>
								</v-col>

								<v-col cols="12">
									<v-avatar class="mt-4" size="75">
										<v-img class="avatar-account" :src="avatarurl"></v-img>
									</v-avatar>
									<avataredit :user="$route.params.id">
										<template #activator="{ on, attrs }">
											<v-row>
												<v-col cols="6" md="2">
													<p class="mt-3">Profiel Foto</p>
												</v-col>
												<v-col cols="6" md="2"><v-btn text v-on="on" v-bind="attrs" class="mt-1" color="primary"> Aanpassen </v-btn></v-col>
											</v-row>
										</template>
									</avataredit>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="2"><v-btn class="mx-auto d-block btn" color="themewhite" to="/auth">Terug</v-btn></v-col>
								<v-col cols="12" md="2"> <v-btn type="submit" class="text-white btn" :loading="loading" color="themepurple"> Opslaan</v-btn></v-col>
							</v-row>
						</v-form>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
		<!-- create program -->
		<v-dialog v-model="createprogramdialog" content-class="theme-popup">
			<div>
				<div class="text-right">
					<v-btn @click="createprogramdialog = false" icon class="" elevation="0" color="themepurple">
						<v-icon>fas fa-times </v-icon>
					</v-btn>
				</div>
				<div class="text-center pb-5">
					<h3>Nieuw programma maken</h3>
				</div>
				<label for=""> Programma naam </label>
				<v-text-field class="theme-input" solo v-model="program.title" title="Programma naam"></v-text-field>
				<label for=""> Programma omschrijving </label>
				<v-textarea class="theme-input" solo v-model="program.description" title="Programma omschrijving"></v-textarea>
				<label for="">Uw gewenste rol</label>
				<v-radio-group color="themepurple" row v-model="program.role">
					<v-radio label="Project Manager" value="PU"></v-radio>
					<v-radio label="Procesmanager/Clustermanager/Lijnmanager" value="PM"></v-radio>
					<v-radio label="Programmamanager" value="OG"></v-radio>
				</v-radio-group>
			</div>
			<div class="text-center pt-5">
				<v-btn class="btn" :loading="loading" @click="submitprogram" color="themepurple" dark>Nieuw programma maken</v-btn>
			</div>
		</v-dialog>

		<v-dialog v-model="editprogramdialog" content-class="theme-fullsceen-dialog" fullscreen>
			<div>
				<div class="text-right">
					<v-btn @click="editprogramdialog = false" icon class="" elevation="0" color="themepurple">
						<v-icon>fas fa-times </v-icon>
					</v-btn>
				</div>
				<div class="text-center pb-5">
					<h3>Programma updaten</h3>
				</div>
				<v-data-table :headers="headers" :items="programusers" :items-per-page="10" class="elevation-0"></v-data-table>
			</div>
			<div class="text-center pt-5">
				<v-btn class="btn" :loading="loading" @click="submitprogram" color="themepurple" dark>Programma updaten</v-btn>
			</div>
		</v-dialog>
	</v-app>
</template>

<script>
import api from "@/services/api";
import topmenubar from "@/components/topmenubar.vue";
import avataredit from "../components/avataredit.vue";
import { mapGetters } from 'vuex'
import * as cookies from "js-cookie";
import config from '../config'
export default {
	name: "Home",
	components: {
		topmenubar,
		avataredit
	},
	computed: {
		...mapGetters({ user: 'getuser' }),
		avatarurl() {
			return `${config.url.cdn}/usercontent/avatars/${this.user.profilepicture}.png`
		}
	},
	data: () => ({
		valid: false,
		show: false,
		loading: false,
		createprogramdialog: false,
		editprogramdialog: false,
		headers: [
			"Voornaam",
			"Achternaam",
			"Email",
			"Functie",
			"Rol",
			"Acties",
		],
		passmessage: "",
		roles: [
			{
				title: "Projectleider",
				val: "PU"
			}, {
				title: "Procesmanager/Clustermanager/Lijnmanager",
				val: "PM"
			}, {
				title: "Programmamanager",
				val: "OG"
			}],
		rules: {
			pass: [
				v => !!v || 'Verplicht',
				v => (v && v.length >= 2) || 'Minimaal 8 tekens',
				v => (v && v.length <= 64) || 'Maximaal 64 tekens',
				v => (/[a-z]/.test(v)) || 'Wachtwoord moet ten minste één kleine letter bevatten',
				v => (/[A-Z]/.test(v)) || 'Wachtwoord moet minstens één hoofdletter bevatten',
				v => (/[0-9]/.test(v)) || 'Wachtwoord moet ten minste één getal bevatten',
				v => (/[@$!#%*?&]/.test(v)) || 'Wachtwoord moet ten minste een van de volgende speciale tekens bevatten: @$!%*?&',
				v => config.regex.password.test(v) || 'Het wachtwoord moet tussen 8 en 64 tekens lang zijn en ten minste één hoofdletter, kleine letter, cijfer en één van de volgende speciale tekens bevatten: @$!%*?&',
			],
		},
		employers: [],
		programs: [],
		program: {
			role: "",
			title: "",
			description: ""
		},
		account: {
			firstname: "",
			lastname: "",
			employer: "",
			functiontitle: "",
			passwd: "",
			rpasswd: "",
			role: "",
			program: ""
		},
		updateaccount: {},
		updateprogramuser: {},
		programusers: []
	}),
	methods: {

		submit() {

			if (this.valid) {
				this.loading = true;
				if (this.account.firstname != this.user.firstname) {
					this.updateaccount.firstname = this.account.firstname;
				}
				if (this.account.lastname != this.user.lastname) {
					this.updateaccount.firstname = this.account.firstname;
				}
				if (this.account.employer != this.user.employer) {
					this.updateaccount.employer = this.account.employer;
				}
				if (this.account.functiontitle != this.user.functiontitle) {
					this.updateaccount.functiontitle = this.account.functiontitle;
				}
				if (this.account.role != this.user.role) {
					this.updateaccount.role = this.account.role;
				}
				if (this.account.passwd == this.account.rpasswd && this.account.passwd != "") {
					this.updateaccount.passwd = this.account.passwd;
				}
				if (this.account.program != this.user.program) {
					this.updateaccount.program = this.account.program;
				}

				api.patch(`${config.url.api}/v1/users/0`, this.updateaccount).then((res) => {
					if (res.data.success == true) {
						this.$toast.success('Account succesvol geupdate!');
						api.get(`/v1/users/0/info`).then((res) => {
							res.data.loggedin = true;
							this.$store.dispatch('setuserdata', res.data)
						}).catch(err => {
							if (err.response.status === 401) {
								cookies.remove('jh_access_token', { domain: config.cookiedomain, path: '/' });
								cookies.remove('jh_refresh_token', { domain: config.cookiedomain, path: '/' });
								cookies.remove('jh_user', { domain: config.cookiedomain, path: '/' });
								location.replace(`${config.url.login}?r=${encodeURI(location.href)}`)
							}
						})
						this.$router.push("/");
					} else {
						this.$toast.error(`Er is iets fout gegaan tijdens het updaten van je account.`)
					}
					this.loading = false
				}).catch((err) => {
					switch (err.response.status) {
						case 401:
							this.$toast.error('Je hebt geen rechten om deze actie uit te voeren!')
							break;
						default:
							this.$toast.error(`Er is iets fout gegaan (${err.response.status})`)
							break;
					}
					this.loading = false
				})
			}
		},
		submitprogram() {
			this.loading = true;
			api.post(`${config.url.api}/v1/programs/0`, this.program).then((res) => {
				if (res.data.success == true) {
					this.$toast.success('Programma succesvol aangemaakt!');
				} else {
					this.$toast.error(`Er is iets fout gegaan tijdens het aanmaken van het programma.`)
				}
				this.loading = false
			}).catch((err) => {
				switch (err.response.status) {
					case 401:
						this.$toast.error('Je hebt geen rechten om deze actie uit te voeren!')
						break;
					default:
						this.$toast.error(`Er is iets fout gegaan (${err.response.status})`)
						break;
				}
				this.loading = false
			})
			this.createprogramdialog = false;
		},
		validate() {
			if (this.account.passwd != this.account.rpasswd) {
				this.passmessage = "Wachtwoorden komen niet overeen!";
			} else {
				this.passmessage = "";
			}
		},
		getEmployers(s = null) {
			if (s == null) {
				api.get(`${config.url.api}/v1/employers/0`).then((res) => {
					if (res.data.success)
						this.employers = res.data.data;
				})
			}
		},
		getPrograms() {
			api.get(`${config.url.api}/v1/programs/0`).then((res) => {
				if (res.data.success) {
					this.programs = res.data.data;
				}
			})
		},
		getProgramUsers() {
			api.get(`${config.url.api}/v1/programs/${this.account.program}/users`).then((res) => {
				if (res.data.success) {
					this.programusers = res.data.data;
				}
			})
		}
	},
	mounted() {
		this.account.firstname = this.user.firstname;
		this.account.lastname = this.user.lastname;
		this.account.employer = this.user.employer;
		this.account.functiontitle = this.user.functiontitle;
		this.account.role = this.user.role;
		this.account.program = this.user.program;

		this.getEmployers();
		this.getPrograms();
	}
};
</script>
<style>
.avatar-account {
	background: #e8e8e8 0% 0% no-repeat padding-box;
}
</style>