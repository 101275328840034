<template>
	<v-dialog v-model="dialog" width="720" persistent>
		<template #activator="{ on, attrs }">
			<slot name="activator" :on="on" :attrs="attrs" />
		</template>
		<v-card>
			<v-card-title> Foto uploaden </v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col>
							<fileupload v-if="!file" :input="input" />
							<div v-else>
								<cropper
									:src="image"
									ref="cropper"
									class="img-fluid"
									:stencil-props="{
										handlers: {},
										movable: false,
										scalable: false,
										aspectRatio: 1,
									}"
									:stencil-size="{
										width: 512,
										height: 512,
									}"
									stencil-component="circle-stencil"
									image-restriction="stencil"
								/>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-progress-linear indeterminate v-if="busy" />
			<v-card-actions>
				<v-btn elevation="0" v-if="file" color="danger" text @click="file = null"> Andere foto uploaden </v-btn>
				<v-spacer />
				<v-btn color="light darken-1" text @click="dialog = false" :disabled="busy">Annuleren</v-btn>
				<v-btn elevation="0" color="primary" @click="submit" :disabled="busy">Opslaan</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import fileupload from './forms/fileupload.vue'
import { mapGetters } from 'vuex'
import api from "@/services/api";
import config from '@/config'

export default {
	components: { fileupload },
	comments: { Cropper },
	props: {
		user: Number
	},
	data: () => ({
		dialog: false,
		file: null,
		busy: false,
	}),
	computed: {
		...mapGetters({ token: 'gettoken', _user: 'getuser' }),
		image() {
			if (this.file !== null) {
				return URL.createObjectURL(this.file)
			} else {
				return ''
			}
		}
	},
	methods: {
		input(e) {
			this.file = e[0]
		},
		async getBlob(canvas) {
			const blob = await new Promise((resolve) => canvas.toBlob(resolve))
			return blob
		},
		submit() {
			this.busy = true;
			const { canvas } = this.$refs.cropper.getResult()
			if (canvas) {
				this.getBlob(canvas).then((blob) => {
					const data = new FormData()
					data.append('file_image', blob)
					api.post(`${config.url.api}/v1/users/0/avatar`, data).then((res) => {
						if (res.data.success) {
							this._user.profilepicture = res.data.r
							this.$toast.success('Profielfoto bijgewerkt')
							this.dialog = false;
						} else {
							this.$toast.error('Fout bij het bijwerken van de profielfoto')
						}
					}).catch(() => {
						this.$toast.error('Fout bij het bijwerken van de profielfoto')
					})
				})
			}
		},
	}
}
</script>

<style>

</style>