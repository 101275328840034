var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"720","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Foto uploaden ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[(!_vm.file)?_c('fileupload',{attrs:{"input":_vm.input}}):_c('div',[_c('cropper',{ref:"cropper",staticClass:"img-fluid",attrs:{"src":_vm.image,"stencil-props":{
									handlers: {},
									movable: false,
									scalable: false,
									aspectRatio: 1,
								},"stencil-size":{
									width: 512,
									height: 512,
								},"stencil-component":"circle-stencil","image-restriction":"stencil"}})],1)],1)],1)],1)],1),(_vm.busy)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-card-actions',[(_vm.file)?_c('v-btn',{attrs:{"elevation":"0","color":"danger","text":""},on:{"click":function($event){_vm.file = null}}},[_vm._v(" Andere foto uploaden ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"light darken-1","text":"","disabled":_vm.busy},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Annuleren")]),_c('v-btn',{attrs:{"elevation":"0","color":"primary","disabled":_vm.busy},on:{"click":_vm.submit}},[_vm._v("Opslaan")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }